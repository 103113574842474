import React, { useState, useEffect } from 'react';
import mqtt from 'mqtt';
import { ReactComponent as WaterRefResetIcon } from './../img/water_ref_reset.svg';
import { ConfirmationWaterRefReset } from './confirmationWaterRefResetPopup';
import { ConfirmationWaterFlush } from './confirmationWaterFlushPopup';
import { ConfirmationScreenReset } from './confirmationScreenResetPopup';
import { ConfirmationCartridgeReset } from './confirmationCartridgeResetPopup';
import { db2, db3 } from '../firebase';
import { Modal, Button, Form } from 'react-bootstrap';
import {
  getDocs,
  setDoc,
  doc,
  updateDoc,
  collection,
} from 'firebase/firestore';
import { set } from 'firebase/database';

const MqttComponent = ({ place, issue, onShow, onClose, userRole, units, devices }) => {
  // const units = collection(db2, 'units');
  const monitoring_user = process.env.MONITORING_USER;
  const monitoring_key = process.env.MONITORING_KEY;

  const isViewer = userRole === "kamleon_viewer";

  const [unit, setUnit] = useState(null);

  const [showConfirmationWaterRefResetPopup, setShowConfirmationWaterRefResetPopup] = useState(false);
  const [showConfirmationWaterFlushPopup, setShowConfirmationWaterFlushPopup] = useState(false);
  const [showConfirmationScreenResetPopup, setShowConfirmationScreenResetPopup] = useState(false);
  const [showConfirmationCartridgeResetPopup, setShowConfirmationCartridgeResetPopup] = useState(false);
  const [confirmationWaterRefReset, setConfirmationWaterRefReset] = useState(false);
  const [confirmationWaterFlush, setConfirmationWaterFlush] = useState(false);
  const [confirmationScreenReset, setConfirmationScreenReset] = useState(false);
  const [confirmationCartridgeReset, setConfirmationCartridgeReset] = useState(false);
  const [unitsData, setUnitsData] = useState([]);
  const [devicesData, setDevicesData] = useState([]);
  const [unitMode, setUnitMode] = useState(null);
  const [unitStatus, setUnitStatus] = useState(null);

  const fetchUnits = async () => {
    try {
      const unitIDToSearch = issue.Installation.Unit_ID;
      setUnit(unitIDToSearch);
      // const unitDocs = await getDocs(units);
      // const unitsArray = units.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      // console.log('Units data:', unitsArray);
      setUnitsData(units);
      // const devicesArray = devices.map((device) => ({ id: device.id, ...device.data() }));
      // console.log('Devices data:', devicesArray);
      setDevicesData(devices);
      // Find the unit with the specified ID
      const unitWithID = units.find(unit => unit.unitID === unitIDToSearch);

      // Check if the unit with the specified ID was found
      if (unitWithID) {
        const statusAttribute = unitWithID.status;
        setUnitStatus(statusAttribute);
        setUnitMode(unitWithID.screen);
      } else {
        console.log('Unit with ID', unitIDToSearch, 'not found.');
      }
    } catch (error) {
      console.error('Error fetching units data:', error);
    }
  };

  const waterResetReference = async () => {
    if (issue.Installation.Unit_ID) {
      // Find the unit in unitsData with the matching ID
      const matchingUnit = unitsData.find((unit) => unit.unitID === issue.Installation.Unit_ID);
      const matchingDevice = devices.find((device) => device.deviceID === matchingUnit.devices.kPodID);
  
      // Check if a matching unit is found
      if (matchingDevice) {
        // Set all properties to 0
        matchingDevice.water_reference.ref_1[0] = 0;
        matchingDevice.water_reference.ref_1[1] = 0;
        matchingDevice.water_reference.ref_1[2] = 0;
  
        matchingDevice.water_reference.ref_2[0] = 0;
        matchingDevice.water_reference.ref_2[1] = 0;
        matchingDevice.water_reference.ref_2[2] = 0;
  
        matchingDevice.water_reference.ref_3[0] = 0;
        matchingDevice.water_reference.ref_3[1] = 0;
        matchingDevice.water_reference.ref_3[2] = 0;
  
        matchingDevice.water_reference.ref_4[0] = 0;
        matchingDevice.water_reference.ref_4[1] = 0;
        matchingDevice.water_reference.ref_4[2] = 0;

        matchingDevice.water_reference.ref_5[0] = 0;
        matchingDevice.water_reference.ref_5[1] = 0;
        matchingDevice.water_reference.ref_5[2] = 0;
  
        matchingDevice.water_reference.ref_6[0] = 0;
        matchingDevice.water_reference.ref_6[1] = 0;
        matchingDevice.water_reference.ref_6[2] = 0;
  
        matchingDevice.water_reference.ref_7[0] = 0;
        matchingDevice.water_reference.ref_7[1] = 0;
        matchingDevice.water_reference.ref_7[2] = 0;
  
        matchingDevice.water_reference.ref_8[0] = 0;
        matchingDevice.water_reference.ref_8[1] = 0;
        matchingDevice.water_reference.ref_8[2] = 0;

        matchingDevice.water_reference.ref_9[0] = 0;
        matchingDevice.water_reference.ref_9[1] = 0;
        matchingDevice.water_reference.ref_9[2] = 0;
  
        matchingDevice.water_reference.ref_10[0] = 0;
        matchingDevice.water_reference.ref_10[1] = 0;
        matchingDevice.water_reference.ref_10[2] = 0;
  
        matchingDevice.water_reference_8b.ref_1 = Array(8).fill(0);
        matchingDevice.water_reference_8b.ref_2 = Array(8).fill(0);
        matchingDevice.water_reference_8b.ref_3 = Array(8).fill(0);
        matchingDevice.water_reference_8b.ref_4 = Array(8).fill(0);
        matchingDevice.water_reference_8b.ref_5 = Array(8).fill(0);
        matchingDevice.water_reference_8b.ref_6 = Array(8).fill(0);
        matchingDevice.water_reference_8b.ref_7 = Array(8).fill(0);
        matchingDevice.water_reference_8b.ref_8 = Array(8).fill(0);
        matchingDevice.water_reference_8b.ref_9 = Array(8).fill(0);
        matchingDevice.water_reference_8b.ref_10 = Array(8).fill(0);

        // Update the Firestore document
        const deviceDocRef = doc(db2, 'devices', matchingDevice.deviceID);
        try {
          await setDoc(deviceDocRef, matchingDevice, { merge: true });
          // Send MQTT message and wait for completion
          await sendMqttMessage(issue.Installation.Unit_ID, matchingUnit.devices.kPodID, 'status', 'init_cartridge');
          waterFlush();
        } catch (error) {
          console.error('Error updating Firestore document:', error);
        }
      } else {
        console.log(`Unit with ID ${issue.Installation.Unit_ID} not found in unitsData`);
      }
    } else {
      console.log('issue.Installation.Unit_ID is not available');
    }
  };

  const sendMqttMessage = (unitId, deviceId, direction, message) => {
    return new Promise((resolve, reject) => {
      const topic = `${unitId}/${deviceId}/${direction}`;
      const client = mqtt.connect('wss://development.kamleoncloud.cat:8885', {
        clientId: 'monitoring_dev',
        username: monitoring_user,
        password: monitoring_key,
      });
      client.on('connect', () => {
        // Once connected, publish the message to the topic
        client.publish(topic, message, () => {
          console.log(`Message sent to '${topic}': ${message}`);
          // Close the connection
          client.end();
          resolve(); // Resolve the promise after the message is sent
        });
      });
  
      client.on('error', (err) => {
        console.error('MQTT error:', err);
        reject(err); // Reject the promise on error
      });
    });
  };

  const waterFlush = async () => {
    const matchingUnit = unitsData.find((unit) => unit.unitID === issue.Installation.Unit_ID);
    // Check if a matching unit is found
    if (matchingUnit) {
      // Set the found unit using setUnit
      setUnit(matchingUnit);
      await sendMqttMessage(issue.Installation.Unit_ID, matchingUnit.devices.displayID, 'config', 'start_flushing');
    } else {
      console.log('issue.Installation.Unit_ID is not available');
    }
  }

  const cartridgeReset = async () => {
    const matchingUnit = unitsData.find((unit) => unit.unitID === issue.Installation.Unit_ID);
    // Check if a matching unit is found
    if (matchingUnit) {
      // Set the found unit using setUnit
      setUnit(matchingUnit);
    
      await sendMqttMessage(issue.Installation.Unit_ID, matchingUnit.devices.displayID, 'config', 'resetCartridge');

      console.log('Cartridge reset command sent');
    } else {
      console.log('issue.Installation.Unit_ID is not available');
    }
  }

  const screenReset = async () => {
    const matchingUnit = unitsData.find((unit) => unit.unitID === issue.Installation.Unit_ID);
    // Check if a matching unit is found
    if (matchingUnit) {
      // Set the found unit using setUnit
      setUnit(matchingUnit);
    
      await sendMqttMessage(issue.Installation.Unit_ID, matchingUnit.devices.displayID, 'config', 'resetScrapp');

      console.log('Screen reset command sent');
    } else {
      console.log('issue.Installation.Unit_ID is not available');
    }
  }

  const updateUnitMode = async (newMode) => {
    try {
      const matchingUnit = unitsData.find((unit) => unit.unitID === issue.Installation.Unit_ID);
      // Check if a matching unit is found
      if (matchingUnit) {
        // Set the found unit using setUnit
        if (newMode === 'outoforder_screen') {
          const message = {
            'screen': 'outoforder_screen',
            'msg': 'Tracking...'
          };
          await sendMqttMessage(issue.Installation.Unit_ID, matchingUnit.devices.displayID, 'screens', JSON.stringify(message));
          setUnitMode(newMode);
        }
      }
  } catch (error) {
      console.error('Error updating unit mode:', error);
    }
  }

  const updateUnitStatus = async (newStatus) => {
    try {
      // Get the reference to the unit document
      const unitRef = doc(db2, 'units', unit);
  
      // Update the status field of the unit document
      await updateDoc(unitRef, { status: newStatus });
      setUnitStatus(newStatus);
  
      console.log(`Status updated successfully for unit with ID ${unit}. New status: ${newStatus}`);
    } catch (error) {
      console.error('Error updating unit status:', error);
    }
  };

  useEffect(() => {
    // MQTT broker connection options
    const topic = 'test';

    // Message to publish
    const message = 'Hello MQTT 3';



    // This code is for publishing mqtt messages in a determined port

    // const client = mqtt.connect('wss://development.kamleoncloud.cat:8885');
    // client.on('connect', () => {
    //   // Once connected, publish the message to the topic
    //   client.publish(topic, message, () => {
    //     console.log(`Message sent to '${topic}': ${message}`);
    //     // Close the connection
    //     client.end();
    //   });
    // });  

    fetchUnits();
    if (confirmationWaterRefReset === true) {
      waterResetReference();
      setConfirmationWaterRefReset(false);
    }
    if (confirmationWaterFlush === true) {
      waterFlush();
      setConfirmationWaterFlush(false);
    }
    if (confirmationScreenReset === true) {
      screenReset();
      setConfirmationScreenReset(false);
    }
    if (confirmationCartridgeReset === true) {
      cartridgeReset();
      setConfirmationCartridgeReset(false);
    }
  }, [confirmationWaterRefReset, confirmationWaterFlush, confirmationScreenReset, confirmationCartridgeReset]);

  useEffect(() => {
      fetchUnits();
  }, []);

  return (
    <div>
      {showConfirmationWaterRefResetPopup && (
        <ConfirmationWaterRefReset setConfirmationWaterRefReset={setConfirmationWaterRefReset} onShow={showConfirmationWaterRefResetPopup} onClose={() => { setShowConfirmationWaterRefResetPopup(false) }} />
      )}
      {showConfirmationWaterFlushPopup && (
        <ConfirmationWaterFlush setConfirmationWaterFlush={setConfirmationWaterFlush} onShow={showConfirmationWaterFlushPopup} onClose={() => { setShowConfirmationWaterFlushPopup(false) }} />
      )}
      {showConfirmationScreenResetPopup && (
        <ConfirmationScreenReset setConfirmationScreenReset={setConfirmationScreenReset} onShow={showConfirmationScreenResetPopup} onClose={() => { setShowConfirmationScreenResetPopup(false) }} />
      )}
      {showConfirmationCartridgeResetPopup && (
        <ConfirmationCartridgeReset setConfirmationCartridgeReset={setConfirmationCartridgeReset} onShow={showConfirmationCartridgeResetPopup} onClose={() => { setShowConfirmationCartridgeResetPopup(false) }} />
      )}
      {place === 'unit' && (
        <Modal
          show={onShow}
          tabIndex="-1"
          onHide={onClose}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title className='ms-auto'>{issue.Installation.Unit_ID !== undefined ? `${issue.Installation.Unit_ID}` : ""}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className='container'>
                <div className='row pb-4'>
                  <div className='col-6 text-end'>
                    <div className="button-container">
                      <Button
                        variant="success"
                        className='mqtt-button'
                        onClick={() => {setShowConfirmationScreenResetPopup(true)}}
                      >
                        <img src={require('./../img/reset_screen.png')} alt="Reset" style={{ width: '30px', height: '30px' }} />
                      </Button>
                      <span className="hover-text">Screen Reset</span>
                    </div>
                  </div>
                  <div className='col-6 text-start'>
                    <div className="button-container">
                      <Button
                        variant="success"
                        className='mqtt-button'
                        onClick={() => {setShowConfirmationCartridgeResetPopup(true)}}
                      >
                        <img src={require('./../img/reset_cartridge.png')} alt="Reset" style={{ width: '30px', height: '30px' }} />
                      </Button>
                      <span className="hover-text">Cartridge Reset</span>
                    </div>
                  </div>
                </div>
                <div className='row pb-2'>
                  <div className='col-6 text-end'>
                    <div className="button-container">
                      <Button
                        variant="success"
                        className='mqtt-button'
                        onClick={() => {setShowConfirmationWaterFlushPopup(true)}}
                      >
                        <img src={require('./../img/water_flush.png')} alt="Reset" style={{ width: '30px', height: '30px' }} />
                      </Button>
                      <span className="hover-text">Water Flush</span>
                    </div>
                  </div>
                  <div className='col-6 text-start'>
                    <div className="button-container">
                      <Button
                        variant="success"
                        className='mqtt-button'
                        onClick={() => {setShowConfirmationWaterRefResetPopup(true)}}
                      >
                        <WaterRefResetIcon style={{ width: '30px', height: '30px' }} />
                      </Button>
                      <span className="hover-text">Water Ref Reset</span>
                    </div>
                  </div>
                </div>
                <div className='row pb-2 mx-5'>
                  <div className=''>
                    <Form.Group className="text-start mt-2" controlId="editNotificationTo">
                      <Form.Label><strong>UNIT STATUS</strong></Form.Label>
                      <Form.Select
                        value={unitStatus !== null ? unitStatus : ""}
                        onChange={(e) => updateUnitStatus(e.target.value)}
                      >
                        <option value="active">Active</option>
                        <option value="disabled">Disabled</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
                <div className='row pb-2 mx-5'>
                  <div className=''>
                    <Form.Group className="text-start mt-2" controlId="editNotificationTo">
                      <Form.Label><strong>UNIT MODE</strong></Form.Label>
                      <Form.Select
                        value={unitMode !== null ? unitMode : ""}
                        onChange={(e) => updateUnitMode(e.target.value)}
                      >
                        <option value="outoforder_screen">Out Of Order</option>
                        <option value="welcome_screen">Welcome Screen</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      )}
      {place === 'sidebar' && (
        <div className="mt-2">
          <div className='row'>
            <label className='form-label text-start'><strong>CARTRIDGE ACTIONS</strong></label>
          </div>
          <div className='row'>
            <div className='col-4'>
              <Button
                variant="success"
                className='mqtt-button'
                onClick={() => {setShowConfirmationWaterRefResetPopup(true)}}
              >
                <WaterRefResetIcon style={{ width: '30px', height: '30px' }} />
              </Button>
            </div>
            <div className='col-4'>
              <Button
                variant="success"
                className='mqtt-button'
                onClick={() => {setShowConfirmationWaterFlushPopup(true)}}
              >
                <img src={require('./../img/water_flush.png')} alt="Reset" style={{ width: '30px', height: '30px' }} />
              </Button>
            </div>
            <div className='col-4'>
              <Button
                variant="success"
                className='mqtt-button'
                onClick={() => {setShowConfirmationCartridgeResetPopup(true)}}
              >
                <img src={require('./../img/reset_cartridge.png')} alt="Reset" style={{ width: '30px', height: '30px' }} />
              </Button>
            </div>
          </div>
          <div className='row mt-2'>
            <label className='form-label text-start'><strong>SCREEN ACTIONS</strong></label>
          </div>
          <div className='row pb-2'>
            <div className='col-4'>
              <Button
                variant="success"
                className='mqtt-button'
                onClick={() => {setShowConfirmationScreenResetPopup(true)}}
              >
                <img src={require('./../img/reset_screen.png')} alt="Reset" style={{ width: '30px', height: '30px' }} />
              </Button>
            </div>
            <div className='col-4'>
              <Button variant="success" className='mqtt-button'>
                <img src={require('./../img/reset_screen.png')} alt="Reset" style={{ width: '30px', height: '30px' }} />
              </Button>
            </div>
            <div className='col-4'>
              <Button variant="success" className='mqtt-button'>
                <img src={require('./../img/reset_screen.png')} alt="Reset" style={{ width: '30px', height: '30px' }} />
              </Button>
            </div>
          </div>
        </div>
      )}
      {place === 'issue' && (
        <div className="mt-2">
          <div className='row pb-2'>
            <div className=''>
              <Form.Group className="text-start mt-2" controlId="editNotificationTo">
                <Form.Label><strong>UNIT STATUS</strong></Form.Label>
                <Form.Select
                  value={unitStatus !== null ? unitStatus : ""}
                  onChange={(e) => updateUnitStatus(e.target.value)}
                  disabled={isViewer}
                  className={isViewer ? 'form-control-muted' : ''}
                >
                  <option value="active">Active</option>
                  <option value="disabled">Disabled</option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>
          <div className='row pb-2'>
            <div className=''>
              <Form.Group className="text-start mt-2" controlId="editNotificationTo">
                <Form.Label><strong>UNIT MODE</strong></Form.Label>
                <Form.Select
                  value={unitMode !== null ? unitMode : ""}
                  onChange={(e) => updateUnitMode(e.target.value)}
                  disabled={isViewer}
                  className={isViewer ? 'form-control-muted' : ''}
                >
                  <option value="outoforder_screen">Out Of Order</option>
                  <option value="welcome_screen">Welcome Screen</option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>
          <div className='row pb-4 mt-2'>
            
            <div className='row'>
              <label className='form-label text-start'><strong>ACCIONATE</strong></label>
            </div>
            <div className='row pb-4'>
              <div className='col-6 text-end'>
                <div className="button-container">
                  <Button
                    variant="success"
                    className={`mqtt-button ${isViewer ? 'btn-muted' : ''}`}
                    onClick={() => {setShowConfirmationScreenResetPopup(true)}}
                    disabled={isViewer}
                  >
                    <img src={require('./../img/reset_screen.png')} alt="Reset" style={{ width: '30px', height: '30px' }} />
                  </Button>
                  <span className="hover-text">Screen Reset</span>
                </div>
              </div>
              <div className='col-6 text-start'>
                <div className="button-container">
                  <Button
                    variant="success"
                    className={`mqtt-button ${isViewer ? 'btn-muted' : ''}`}
                    onClick={() => {setShowConfirmationCartridgeResetPopup(true)}}
                    disabled={isViewer}
                  >
                    <img src={require('./../img/reset_cartridge.png')} alt="Reset" style={{ width: '30px', height: '30px' }} />
                  </Button>
                  <span className="hover-text">Cartridge Reset</span>
                </div>
              </div>
            </div>
            <div className='row pb-2'>
              <div className='col-6 text-end'>
                <div className="button-container">
                  <Button
                    variant="success"
                    className={`mqtt-button ${isViewer ? 'btn-muted' : ''}`}
                    onClick={() => {setShowConfirmationWaterFlushPopup(true)}}
                    disabled={isViewer}
                  >
                    <img src={require('./../img/water_flush.png')} alt="Reset" style={{ width: '30px', height: '30px' }} />
                  </Button>
                  <span className="hover-text">Water Flush</span>
                </div>
              </div>
              <div className='col-6 text-start'>
                <div className="button-container">
                  <Button
                    variant="success"
                    className={`mqtt-button ${isViewer ? 'btn-muted' : ''}`}
                    onClick={() => {setShowConfirmationWaterRefResetPopup(true)}}
                    disabled={isViewer}
                  >
                    <WaterRefResetIcon style={{ width: '30px', height: '30px' }} />
                  </Button>
                  <span className="hover-text">Water Ref Reset</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MqttComponent;
